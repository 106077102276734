import { useState } from "react";
import Modal from "../Base/Modal";
import { toast } from "react-toastify";

export default function NewBotWalletDialog({ isOpen, onOK, onCancel, min, max }) {
    const [countOfHolder, setCountOfHolder] = useState("");
    const [countOfShark, setCountOfShark] = useState("");
    const [countOfWhale, setCountOfWhale] = useState("");
    const [fresh, setFresh] = useState(false);

    const handleOK = () => {
        if (countOfHolder !== "" && (countOfHolder > max || countOfHolder < min)) {
            toast.warn("Please input correct count of Holder wallet.");
            return;
        }

        if (countOfShark !== "" && (countOfShark > max || countOfShark < min)) {
            toast.warn("Please input correct count of Shark wallet.");
            return;
        }

        if (countOfWhale !== "" && (countOfWhale > max || countOfWhale < min)) {
            toast.warn("Please input correct count of Whale wallet.");
            return;
        }
        onOK({ countOfHolder, countOfShark, countOfWhale });
    };

    const handleCancel = () => {
        setCountOfHolder("");
        setCountOfShark("");
        setCountOfWhale("");
        onCancel();
    };

    const handleHolderChange = (e) => {
        const newValue = e.target.value;
        setCountOfHolder(newValue);
    };
    const handleSharkChange = (e) => {
        const newValue = e.target.value;
        setCountOfShark(newValue);
    };
    const handleWhaleChange = (e) => {
        const newValue = e.target.value;
        setCountOfWhale(newValue);
    };

    return (
        <Modal isOpen={isOpen} onClose={handleCancel}>
            <div className="flex flex-col pt-5 w-[440px]">
                <div className="w-full h-auto bg-slate-title rounded-t-[8px] flex justify-start items-center px-5 py-3">
                    <div className="text-white text-[24px] font-medium font-poppins leading-normal">
                        Set Bot Wallet Count
                    </div>
                </div>
                <div className="w-full h-auto px-5 py-5 md:py-0 bg-slate-900 bg-opacity-90  rounded-b-[8px] items-center">
                    <div className="mt-3">
                        <label className="block my-1 text-white">
                            {`Wallet Count (min: ${min},      max: ${max})`}
                        </label>
                        <div className="flex gap-2">
                            <input type="number"
                                className="w-full h-[40px] rounded-[6px] bg-[rgba(255,255,255,0.1)] px-[15px] text-white outline-none focus:border border-gray-800 focus:border-baseColor"
                                placeholder="Holder Wallet"
                                min={min}
                                max={max}
                                value={countOfHolder}
                                onChange={handleHolderChange} />
                            <input type="number"
                                className="w-full h-[40px] rounded-[6px] bg-[rgba(255,255,255,0.1)] px-[15px] text-white outline-none focus:border border-gray-800 focus:border-baseColor"
                                placeholder="Shark Wallet"
                                min={min}
                                max={max}
                                value={countOfShark}
                                onChange={handleSharkChange} />
                            <input type="number"
                                className="w-full h-[40px] rounded-[6px] bg-[rgba(255,255,255,0.1)] px-[15px] text-white outline-none focus:border border-gray-800 focus:border-baseColor"
                                placeholder="Whale Wallet"
                                min={min}
                                max={max}
                                value={countOfWhale}
                                onChange={handleWhaleChange} />
                        </div>
                    </div>
                    <div className="flex justify-center">
                        <button className="w-[70px] sm:w-[120px] m-5 h-6 sm:h-10 px-2 sm:px-4 py-1 bg-gradient-to-r border-teal-600 rounded-[40px] justify-center items-center gap-2.5 inline-flex hover:bg-gradient-to-br active:scale-95 transition duration-100 ease-in-out transform focus:outline-none"
                            onClick={handleOK}>
                            <div className="text-base font-semibold leading-normal text-center text-white sm:text-lg font-poppins">
                                OK
                            </div>
                        </button>
                        <button className="w-[70px] sm:w-[120px] m-5 h-6 sm:h-10 px-2 sm:px-4 py-1 bg-gradient-to-r bg-transparent border border-baseColor rounded-[40px] justify-center items-center gap-2.5 inline-flex hover:bg-gradient-to-br active:scale-95 transition duration-100 ease-in-out transform focus:outline-none"
                            onClick={handleCancel}>
                            <div className="text-base font-semibold leading-normal text-center text-white sm:text-lg font-poppins">
                                Cancel
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    );
}
