const RefreshIcon = (props) => {

    return (
        <svg
            className={`text-white ${props.className}`}
            viewBox="0 0 16 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M3.60435 5.63238C3.84499 4.95109 4.23564 4.31043 4.78567 3.76352C6.7389 1.81029 9.90471 1.81029 11.8579 3.76352L12.3923 4.30105H11.3204C10.7673 4.30105 10.3204 4.74795 10.3204 5.30111C10.3204 5.85426 10.7673 6.30116 11.3204 6.30116H14.805H14.8175C15.3706 6.30116 15.8175 5.85426 15.8175 5.30111V1.80091C15.8175 1.24776 15.3706 0.800858 14.8175 0.800858C14.2643 0.800858 13.8174 1.24776 13.8174 1.80091V2.90098L13.2705 2.35094C10.536 -0.383583 6.10449 -0.383583 3.36997 2.35094C2.60742 3.11349 2.05739 4.01041 1.71987 4.96984C1.53549 5.49174 1.8105 6.06053 2.32928 6.24491C2.84806 6.4293 3.41997 6.15428 3.60435 5.6355V5.63238ZM1.53861 8.3419C1.38236 8.38878 1.23235 8.47316 1.11047 8.59817C0.985458 8.72318 0.901079 8.87318 0.857326 9.03569C0.847951 9.07319 0.838575 9.11382 0.832325 9.15445C0.822949 9.20758 0.819824 9.2607 0.819824 9.31383V12.8015C0.819824 13.3547 1.26672 13.8016 1.81988 13.8016C2.37304 13.8016 2.81994 13.3547 2.81994 12.8015V11.7046L3.36997 12.2515C6.10449 14.9829 10.536 14.9829 13.2674 12.2515C14.0299 11.489 14.5831 10.592 14.9206 9.63573C15.105 9.11382 14.83 8.54504 14.3112 8.36065C13.7924 8.17627 13.2205 8.45128 13.0361 8.97006C12.7955 9.65135 12.4048 10.292 11.8548 10.8389C9.90158 12.7922 6.73578 12.7922 4.78255 10.8389L4.77942 10.8358L4.24502 10.3014H5.32008C5.87323 10.3014 6.32013 9.85449 6.32013 9.30133C6.32013 8.74818 5.87323 8.30128 5.32008 8.30128H1.83238C1.78238 8.30128 1.73238 8.3044 1.68237 8.31065C1.63237 8.3169 1.58549 8.32628 1.53861 8.3419Z"
                fill="currentColor"
            />
        </svg>
    );
}

export default RefreshIcon;